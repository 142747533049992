import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
	Grid,
	Typography,
	AppBar,
	Toolbar,
	Dialog,
	IconButton,
	Stepper,
	StepLabel,
	Slide,
	Step
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectAreasTable from './SelectAreasTable';
import AddActivitiesToAreas from './AddActivitiesToAreas';
import EditPositionMetaData from './EditPositionMetaData';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function getSteps() {
	return [ 'Metadata szerkesztése', 'Területek választása', 'Feladatok hozzárendelése' ];
}

export default function AddNewAreasToWorkerPosition({
	venue,
	worksheets,
	position,
	newPositionId,
	isEditWorkerPosition,
	setIsOpenDialog,
	isOpenDialog,
	saveNewAreasOnWorkerPosition
}) {
	const [ activeStep, setActiveStep ] = React.useState(0);
	const [ skipped, setSkipped ] = React.useState(new Set());
	const [ selectedAreas, setSelectedAreas ] = React.useState();
	const steps = getSteps();
	const classes = useStyles();

	const doFinish = (areasWithActivities) => {
		saveNewAreasOnWorkerPosition(position.id, areasWithActivities);
		setIsOpenDialog(false);
	};

	return (
		<Dialog
			disableEnforceFocus
			fullScreen
			open={isOpenDialog}
			onClose={(event) => setIsOpenDialog(false)}
			TransitionComponent={Transition}
		>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h5" className={classes.title}>
						{position.name} poszt szerkesztése
					</Typography>
					<IconButton
						aria-label="close"
						color="inherit"
						onClick={() => {
							if (newPositionId != null && position.id == newPositionId) {
								delete venue.positions[newPositionId];
							}
							setIsOpenDialog(false);
						}}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				sx={{
					padding: 5
				}}
			>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{activeStep == 0 ? <EditPositionMetaData position={position} setActiveStep={setActiveStep} /> : ''}
			{activeStep == 1 ? (
				<SelectAreasTable
					position={position}
					venue={venue}
					selectedAreas={selectedAreas ? selectedAreas : Object.keys(position.areas)}
					setSelectedAreas={setSelectedAreas}
					setActiveStep={setActiveStep}
				/>
			) : (
				''
			)}
			{activeStep == 2 ? (
				<AddActivitiesToAreas
					position={position}
					venue={venue}
					selectedAreas={selectedAreas}
					setActiveStep={setActiveStep}
					doFinish={doFinish}
					worksheets={worksheets}
				/>
			) : (
				''
			)}
		</Dialog>
	);
}
