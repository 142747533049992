import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import ManageTemplates from './templates/ManageTemplates';
import ManageAreas from './areas/ManageAreas';
import ManageGroups from './groups/ManageGroups';
import ManageShare from './share/ManageShare';
import ManageCategories from './categories/ManageCategories';
import ManageVenue from './venue/ManageVenue';
import ManageWorksheets from './worksheets/ManageWorksheets';
import ManageWorkerPositions from './workerpositions/ManageWorkerPositions';

import { Paper, Grid, Button, Divider, Tab, AppBar, Box, Tabs } from '@mui/material';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		felxGrow: 1
	},
	formControl: {
		margin: useTheme().spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: useTheme().spacing(2)
	},
	deleteButton: {
		backgroundColor: '#E30000',
		color: '#FFFFFF'
	}
}));

function EditVenueView({
	venue,
	updateVenue,
	templates,
	templatesMap,
	cleaningActivities,
	cleaningActivitiesMap,
	users,
	updateBrowserHistory
}) {
	const classes = useStyles();
	const [ value, setValue ] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<AppBar position="static" color="default">
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						<Tab label="Űrlapok" {...a11yProps(0)} />
						<Tab label="Kategoriak" {...a11yProps(1)} />
						<Tab label="Terület lista" {...a11yProps(2)} />
						<Tab label="Feladatlisták" {...a11yProps(3)} />
						<Tab label="Posztok" {...a11yProps(4)} />
						<Tab label="Csoportok" {...a11yProps(5)} />
						<Tab label="Megosztás" {...a11yProps(6)} />
						<Tab label="Létesítmény" {...a11yProps(7)} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0} hidden={value !== 0}>
					<ManageTemplates venue={venue} templates={templates} updateVenue={updateVenue} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ManageCategories
						venue={venue}
						templatesMap={templatesMap}
						updateBrowserHistory={updateBrowserHistory}
					/>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ManageAreas venue={{...venue}} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<ManageWorksheets
						venue={venue}
						cleaningActivities={cleaningActivities}
						cleaningActivitiesMap={cleaningActivitiesMap}
					/>
				</TabPanel>
				<TabPanel value={value} index={4}>
					<ManageWorkerPositions
						venue={venue}
						cleaningActivities={cleaningActivities}
						cleaningActivitiesMap={cleaningActivitiesMap}
					/>
				</TabPanel>
				<TabPanel value={value} index={5}>
					<ManageGroups venue={venue} updateBrowserHistory={updateBrowserHistory} />
				</TabPanel>
				<TabPanel value={value} index={6}>
					<ManageShare venue={venue} updateVenue={updateVenue} users={users} />
				</TabPanel>
				<TabPanel value={value} index={7}>
					<ManageVenue venue={venue} />
				</TabPanel>
			</Grid>
		</Grid>
	);
}

export default EditVenueView;
