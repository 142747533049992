import React, { forwardRef, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import UUID from 'uuid/v4';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function EditableMasterCleaningActivitiesTableList({
	masterCleaningActivities,
	setDataChanged,
	deleteCleaningActivitiesOnOrg,
	addMasterCleaningActivitiesToUpdateList
}) {
	const [ columns ] = React.useState([
		{
			title: 'Területek',
			field: 'areas',
			render: (rowData) => (
				<ul>{rowData.areas && rowData.areas.split(';').map((element) => <li> {element}</li>)}</ul>
			)
		},
		{ title: 'Feladat', field: 'description' },
		{
			title: 'Eljárások',
			field: 'methods',
			render: (rowData) => (
				<ul>{rowData.methods && rowData.methods.split(';').map((element) => <li> {element}</li>)}</ul>
			)
		}
	]);
	const [ data, setData ] = React.useState([]);

	useEffect(() => {
		if (data.length == 0 && Object.keys(masterCleaningActivities).length > 0) {
			generateDataFromVenue();
		}
	});

	const updateCleaningActivities = (newData, oldData) => {
		return new Promise((resolve) => {
			resolve();
			const dataUpdate = [ ...data ];
			const index = oldData.tableData.id;
			dataUpdate[index] = { ...newData };
			setData([ ...dataUpdate ]);
			let key = newData.id;
			delete newData.id;
			if (newData.areas != null) {
				newData.areas = newData.areas.split(';');
			}
			if (newData.methods != null) {
				newData.methods = newData.methods.split(';');
			}
			masterCleaningActivities[key] = newData;
			addMasterCleaningActivitiesToUpdateList(masterCleaningActivities[key], key);
			setDataChanged(true);
		});
	};

	const addCleaningActivity = (cleaningActivity) => {
		return new Promise((resolve) => {
			// setTimeout(() => {
			resolve();
			let key = UUID();
			cleaningActivity.id = key;
			setData([ ...data, { ...cleaningActivity } ]);
			var temp = [ ...data, { ...cleaningActivity } ];
			if (cleaningActivity.areas != null) {
				cleaningActivity.areas = cleaningActivity.areas.split(';');
			}
			if (cleaningActivity.methods != null) {
				cleaningActivity.methods = cleaningActivity.methods.split(';');
			}
			masterCleaningActivities[key] = cleaningActivity;
			addMasterCleaningActivitiesToUpdateList(masterCleaningActivities[key], key);
			setDataChanged(true);
		});
	};

	const handleDeleteRows = (evt, rowData) => {
		return new Promise((resolve) => {
			var dataToDelete = [];
			let _data = [ ...data ];
			rowData.forEach((rd) => {
				_data = _data.filter((t) => t.id !== rd.tableData.id);
				if (rd.id != null) {
					delete masterCleaningActivities[rd.id];
					dataToDelete.push(rd.id);
				}
			});
			if (dataToDelete.length > 0) {
				deleteCleaningActivitiesOnOrg(dataToDelete);
			}
			setData(_data);
			resolve();
		});
	};

	const generateDataFromVenue = () => {
		if (data.length < 1 && Object.keys(masterCleaningActivities.length > 0)) {
			var rows = [];
			Object.keys(masterCleaningActivities).forEach(function(key) {
				let masterCleaningActivity = masterCleaningActivities[key];
				rows.push({
					areas: masterCleaningActivity.areas && masterCleaningActivity.areas.join(';'),
					methods: masterCleaningActivity.methods && masterCleaningActivity.methods.join(';'),
					description: masterCleaningActivity.description,
					icon: masterCleaningActivity.icon,
					id: key
				});
			});
			setData(rows);
		}
	};

	return (
		<div>
			<MaterialTable
				icons={tableIcons}
				title="Termek"
				columns={columns}
				data={data}
				options={{
					exportButton: true,
					toolbar: true,
					size: 'small',
					paging: false,
					filtering: true,
					addRowPosition: 'first',
					selection: true,
					padding: 'dense',
					exportMenu: [
						{
							label: 'Export PDF',
							exportFunc: (cols, datas) => ExportPdf(cols, datas, 'torzsadatok-takaritasi-feladatok')
						},
						{
							label: 'Export CSV',
							exportFunc: (cols, datas) => ExportCsv(cols, datas, 'torzsadatok-takaritasi-feladatok')
						}
					]
				}}
				// cellEditable={{
				// 	cellStyle: {},
				// 	onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
				// 		return new Promise((resolve, reject) => {
				// 			console.log('newValue: ' + newValue);
				// 			setTimeout(resolve, 1000);
				// 		});
				// 	}
				// }}
				editable={{
					onRowAdd: (newData) => addCleaningActivity(newData),
					onRowUpdate: (newData, oldData) => updateCleaningActivities(newData, oldData)
				}}
				actions={[
					{
						icon: () => <Delete />,
						tooltip: 'Törlés',
						onClick: (evt, data) => handleDeleteRows(evt, data)
					}
				]}
			/>
		</div>
	);
}
